import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../containers/layout';
import { PrivacyTemplate } from '../pageTemplates/privacy';

const Datenschutz = ({ data }) => {
  return (
    <Layout>
      <PrivacyTemplate data={data} />
    </Layout>
  );
};
export const query = graphql`
  query Privacy {
    strapi {
      privacy {
        text
        subheader
        meta {
          title
          description
        }
      }
    }
  }
`;

export default Datenschutz;
